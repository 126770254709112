.Project-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    font-family: 'Epilogue', sans-serif;
    color: #2D2D2D;

    padding-top: 1em;
    padding-bottom: 4em;
}

.Project {
    width: 90%;
    max-width: 80em;

    background-color: #FFFFFF;

    /* border: 1px solid black; */
    border-radius: 32px;

    overflow: hidden;

    display: grid;
    grid-template-columns: [left] 3em [left-pad] 1fr [mid] 1fr [right-pad] 3em [right];
    grid-template-rows: [top] 3em [top-pad] 400px [bottom-pad] 3em [bottom];

    position: relative;

    box-shadow: 0px 4px 62px 7px rgba(0, 0, 0, 0.05);
}

.Project-text-container {
    grid-column: mid / right-pad;
    grid-row: top-pad / bottom-pad;

    min-width: 200px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5em;

    padding-left: 5vw;
}

.Project-image-container {
    grid-column: left / mid;
    grid-row: top / bottom;

    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Project-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Project-name {
    font-size: 2.3em;
    font-weight: 600;
}

.Project-description {
    font-size: 1.4em;
    font-weight: 400;
    line-height: 1.4em;
}

.Hover {
    grid-column: left / right;
    grid-row: top / bottom;

    background-color: #bfd1f1;

    opacity: 0%;
    transition: opacity 400ms;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em;

    color: #081837;

    z-index: 1;
}

.Project:hover .Hover {
    opacity: 100%;
    transition: opacity 400ms;
}

.Hover-name {
    font-weight: 600;
    font-size: 4em;

    padding-top: 1em;
}

.Hover-link {
    font-weight: 600;
    font-size: 1.6em;

    border: 2px solid #081837;
    border-radius: 32px;

    padding-left: 2.2em;
    padding-right: 2.2em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;

    margin-top: 1.3em;
    margin-bottom: 1.3em;

    transition: background-color 200ms ease-out, color 200ms ease-out;
}

.Hover-link:hover {
    background-color: #081837;
    color: #bfd1f1;
}

.Hover-cat {
    fill: #081837;
}

.Project-link {
    display: none;
}

@media (max-width: 1000px) {
    .Hover-name {
        font-size: 2.7em;
    }
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .Project {
        width: 75%;
        max-width: 600px;

        display: grid;
        grid-template-columns: [left] 2em [left-pad] 1fr [right-pad] 2em [right];
        grid-template-rows: [top] 2em [top-pad] auto [image-end] auto [text-end] auto [bottom-pad] 2em [bottom];
    }

    .Project-image-container {
        max-height: 300px;

        grid-column: left / right;
        grid-row: top / image-end;
    }

    .Project-image {
        height: auto;
        width: 100%;
    }

    .Project-name {
        font-size: 6vw;
    }

    .Project-description {
        font-size: 4vw;
    }

    .Project-text-container {
        grid-column: left-pad / right-pad;
        grid-row: image-end / text-end;

        padding-left: 2vw;
        padding-top: 3em;
        padding-bottom: 3em;

        gap: 5vw;
    }

    .Hover {
        display: none;
        z-index: -1;
    }

    .Project-link {
        grid-column: left-pad / right-pad;
        grid-row: text-end / bottom-pad;

        display: flex;
        justify-content: center;

        font-weight: 600;
        font-size: 3.5vw;

        padding-left: 2em;
        padding-right: 2em;
        padding-top: 0.8em;
        padding-bottom: 0.7em;

        background-color: #2D2D2D;
        color: #FFFFFF;
        border-radius: 15px;
    }
}