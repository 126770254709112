.ProjectPage {
    font-family: 'Epilogue', sans-serif;
    color: #2D2D2D;
    font-weight: 400;
    font-size: 1.2em;
}

.ProjectPage .Banner {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4em;

    position: relative;
    overflow: hidden;

    min-height: 30vh;
    max-height: 60vh;

    background-color: #C3EFD0;

    padding-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.ProjectPage .BannerSingleImage img {
    width: 100%;
    max-width: 1100px;

    object-fit: cover;
}

.ProjectPage .Content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: #FFFFFF;

    gap: 3em;

    padding-bottom: 5em;
}

.ProjectPage .Section {
    width: 75%;
    max-width: 1000px;

    padding-bottom: 3em;
    border-bottom: 1px solid black;

    display: flex;
    flex-direction: column;

    gap: 0.5em;
}

.ProjectPage .Section .Columns {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1em;
}

.ProjectPage .Section .Columns img {
    object-fit: cover;
}

.ProjectPage .Section .Columns .col-1 {width: 8.33%;}
.ProjectPage .Section .Columns .col-2 {width: 16.66%;}
.ProjectPage .Section .Columns .col-3 {width: 25%;}
.ProjectPage .Section .Columns .col-4 {width: 33.33%;}
.ProjectPage .Section .Columns .col-5 {width: 41.66%;}
.ProjectPage .Section .Columns .col-6 {width: 50%;}
.ProjectPage .Section .Columns .col-7 {width: 58.33%;}
.ProjectPage .Section .Columns .col-8 {width: 66.66%;}
.ProjectPage .Section .Columns .col-9 {width: 75%;}
.ProjectPage .Section .Columns .col-10 {width: 83.33%;}
.ProjectPage .Section .Columns .col-11 {width: 91.66%;}
.ProjectPage .Section .Columns .col-12 {width: 100%;}

.ProjectPage .Section:last-of-type {
    border-bottom: none;
}

.ProjectPage .Header {
    width: 100%;

    display: grid;
    grid-template-columns: [left] 1fr [col] 1fr [col] 0.7fr [right];
    grid-template-rows: [top] auto [title] auto [headings] auto [bottom];
}

.ProjectPage .Header .Title {
    grid-column: left / right;
    grid-row: top / title;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 0.2em;

    font-weight: 700;
    font-size: 4em;

    padding-top: 1em;
    padding-bottom: 1em;
}

.ProjectPage .Header .Timeline {
    grid-column: left / 2;
    grid-row: title / headings;

    font-weight: 500;
    font-size: 1.5em;
    color: #6A6A6A;

    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 2em;
}

.ProjectPage .Header .Responsabilities {
    grid-column: 2 / 3;
    grid-row: title / headings;

    font-weight: 500;
    font-size: 1.5em;
    color: #6A6A6A;

    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 2em;
}

.ProjectPage .Header .Tools {
    grid-column: 3 / right;
    grid-row: title / headings;

    font-weight: 500;
    font-size: 1.5em;
    color: #6A6A6A;

    padding-top: 1em;
    padding-bottom: 1em;
}

.ProjectPage .Header .Timeline-content {
    grid-column: left / 2;
    grid-row: headings / bottom;

    font-weight: 400;
    font-size: 1.1em;
    color: #6A6A6A;
    line-height: 1.2em;

    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 2em;
}

.ProjectPage .Header .Responsabilities-content {
    grid-column: 2 / 3;
    grid-row: headings / bottom;

    font-weight: 400;
    font-size: 1.1em;
    color: #6A6A6A;
    line-height: 1.2em;

    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 2em;
}

.ProjectPage .Header .Tools-content {
    grid-column: 3 / right;
    grid-row: headings / bottom;

    font-weight: 400;
    font-size: 1.1em;
    color: #6A6A6A;
    line-height: 1.2em;

    padding-top: 1em;
    padding-bottom: 1em;
}

.ProjectPage .Section h1 {
    font-size: 1.2em;
    font-weight: 400;
    letter-spacing: 0.1em;

    margin-top: 1em;
    margin-bottom: 1em;
}

.ProjectPage .Section h2 {
    font-size: 2em;
    font-weight: 400;
    line-height: 1.6em;

    margin-top: 1em;
    margin-bottom: 0em
}

.ProjectPage .Section p,
.ProjectPage .Section ol,
.ProjectPage .Section ul {
    font-size: 1.1em;
    line-height: 1.8em;
}

.ProjectPage .Section b {
    font-weight: 600;
}

.ProjectPage .Section .highlight {
    background-color: #E2F1EA;

    padding-top: 1em;
    padding-bottom: 1em;

    padding-left: 2em;
    padding-right: 2em;
}

.ProjectPage .Section .Special-h2 {
    display: grid;
    grid-template-columns: [left] 1fr [right];
    grid-template-rows: [top] 1fr [bottom];

    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.ProjectPage .Section .Special-h2 h2 {
    grid-column: left / right;
    grid-row: top / bottom;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-weight: 600;

    margin-top: 0;
    margin-bottom: 0;

    z-index: 1;
}

.ProjectPage .Section .Special-h2 .background-text {
    grid-column: left / right;
    grid-row: top / bottom;

    display: flex;
    justify-content: center;

    font-weight: 500;
    font-size: 13em;

    opacity: 0.15;

    padding-top: 0.2em;

    z-index: 0;
}

.ProjectPage .Section img {
    width: 100%;

    margin-top: 1em;
    margin-bottom: 1em;
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .ProjectPage .Banner {
        min-height: 30vh;
        max-height: 40vh;
    }

    .ProjectPage .BannerSingleImage img {
        height: 10%;
    }

    .ProjectPage .Section .Columns {
        flex-direction: column;
        align-items: center;
        gap: 0em;
    }

    .ProjectPage .Section img {
        margin-top: 0.7em;
        margin-bottom: 0.7em;
    }

    .ProjectPage .Section .Columns .col-xs-1 {width: 8.33%;}
    .ProjectPage .Section .Columns .col-xs-3 {width: 25%;}
    .ProjectPage .Section .Columns .col-xs-2 {width: 16.66%;}
    .ProjectPage .Section .Columns .col-xs-4 {width: 33.33%;}
    .ProjectPage .Section .Columns .col-xs-5 {width: 41.66%;}
    .ProjectPage .Section .Columns .col-xs-6 {width: 50%;}
    .ProjectPage .Section .Columns .col-xs-7 {width: 58.33%;}
    .ProjectPage .Section .Columns .col-xs-8 {width: 66.66%;}
    .ProjectPage .Section .Columns .col-xs-9 {width: 75%;}
    .ProjectPage .Section .Columns .col-xs-10 {width: 83.33%;}
    .ProjectPage .Section .Columns .col-xs-11 {width: 91.66%;}
    .ProjectPage .Section .Columns .col-xs-12 {width: 100%;}

    .ProjectPage .Header {
        grid-template-rows: [top] auto [title] auto [heading] auto [content] auto [heading] auto [content] auto [heading] auto [bottom];
    }

    .ProjectPage .Header .Timeline {
        grid-column: left / right;
        grid-row: title / heading 1;

        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 0em;

        font-size: 5vw;

        display: flex;
        justify-content: center;
    }

    .ProjectPage .Header .Timeline-content {
        grid-column: left / right;
        grid-row: heading 1 / content 1;

        padding-top: 1vw;
        padding-bottom: 2.5em;
        padding-right: 0em;

        font-size: 4vw;

        display: flex;
        justify-content: center;
    }

    .ProjectPage .Header .Responsabilities {
        grid-column: left / right;
        grid-row: content 1 / heading 2;

        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-right: 0em;

        font-size: 5vw;

        display: flex;
        justify-content: center;
    }

    .ProjectPage .Header .Responsabilities-content {
        grid-column: left / right;
        grid-row: heading 2 / content 2;

        padding-top: 1vw;
        padding-bottom: 2.5em;
        padding-right: 0em;

        font-size: 4vw;

        display: flex;
        justify-content: center;
    }

    .ProjectPage .Header .Tools {
        grid-column: left / right;
        grid-row: content 2 / heading 3;

        padding-top: 0.5em;
        padding-bottom: 0.5em;

        font-size: 5vw;

        display: flex;
        justify-content: center;
    }

    .ProjectPage .Header .Tools-content {
        grid-column: left / right;
        grid-row: heading 3 / bottom;

        padding-top: 1vw;

        font-size: 4vw;

        display: flex;
        justify-content: center;
    }

    .ProjectPage .Section {
        width: 90%;
    }

    .ProjectPage .Header .Title {
        font-size: 10vw;

        padding-top: 10vw;
    }

    .ProjectPage .Section h1 {
        font-size: 4vw;
    }

    .ProjectPage .Section h2 {
        font-size: 8vw;
    }

    .ProjectPage .Section p,
    .ProjectPage .Section ol,
    .ProjectPage .Section ul {
        font-size: 4.5vw;
    }
}