.Intro-section {
    display: flex;
    justify-content: center;

    padding-top: 5em;
    padding-bottom: 5em;
}

.Intro-container {
    display: grid;
    grid-template-columns: [left] 1fr [right];
    grid-template-rows: [top] 1fr [bottom];

    max-width: 125em;
    width: 100%;
}

.Intro-back-container {
    grid-column: left / right;
    grid-row: top / bottom;

    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;

    gap: 500px;
    opacity: 0.0;

    animation-name: hands-come-together;
    animation-duration: 4000ms;
    animation-delay: 200ms;
    animation-fill-mode: forwards;
}

@keyframes hands-come-together {
    90% {
        opacity: 1.0;
    }

    100% {
        opacity: 1.0;
        gap: 0em;
    }
}

.Hand-Left {
    width: 60em;
    transform: translate(160px, 30px);

    opacity: 1.0;
}

.Hand-Right {
    width: 60em;
    transform: translate(-80px, 0px);

    opacity: 1.0;
}

.Intro-front-container {
    max-width: 1500px;
    justify-self: center;

    grid-column: left / right;
    grid-row: top / bottom;

    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-items: start;
    align-items: center;

    gap: 2em;

    font-family: 'Epilogue', sans-serif;
    color: #2D2D2D;

    padding-top: 7em;
    padding-bottom: 7em;
    padding-left: 3em;
    padding-right: 3em;

    margin-left: 3em;
    margin-right: 3em;
}

.Hello {
    font-weight: 600;
    font-size: 1.5em;

    width: 100%;
}

.UX-Designer {
    font-weight: 700;
    font-size: 4em;
    line-height: 1.5em;

    width: 100%;
}

.Why {
    font-weight: 500;
    font-size: 1em;

    width: 100%;

    line-height: 1.5em;
}

.Blue {
    color: #0A34A4;
}

@media (max-width: 1342px) {
    /* For mobile phones: */

    .Intro-section {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .Intro-front-container {
        justify-self: auto;
        padding-top: 15vw;
        padding-bottom: 15vw;
        padding-left: 3vw;
        padding-right: 3vw;

        margin-left: 0em;
        margin-right: 0em;
    }

    .Hand-Left {
        width: 80vw;
        transform: translate(20vw, -1em);
    }

    .Hand-Right {
        width: 80vw;
        transform: translate(-14vw, -1em);
    }

    .Hello {
        width: 100%;
        font-size: 2.5vw;
    }

    .UX-Designer {
        width: 100%;
        font-size: 5.2vw;
    }

    .Why {
        width: 100%;
        font-size: 1.8vw;
    }

    .Intro-back-container {
        gap: 300px;
    }

    @keyframes hands-come-together {
        0% {
            gap: 70vw;
        }

        90% {
            opacity: 1.0;
        }

        100% {
            opacity: 1.0;
            gap: 15vw;
        }
    }
}

@media (max-width: 768px) {
    .Hello {
        font-size: 3.5vw;
    }

    .Why {
        font-size: 3vw;
    }
}