.BeelineProject .Banner {
    background-color: #FDF7BB;
}

.ProjectPage .BannerSingleImage img {
    max-width: 800px;

    object-fit: cover;
}

.BeelineProject .Header .Title {
    font-size: 3em;
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .BeelineProject .Header .Title {
        font-size: 6.5vw;
        margin-top: 0.5em;

        gap: 0.4em;
    }

}