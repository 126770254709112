.PhoneScreen {
    height: fit-content;
    min-width: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 35px;
    box-shadow: 0px 8px 70px 7px rgb(85, 85, 85, 0.15);
}

.PhoneScreen img {
    transition: opacity 400ms ease-out;
    width: 230px;
}

.PhoneScreen .skeleton {
    width: 230px;
    height: 475px;

    border-radius: 35px;

    position: relative;
    overflow: hidden;
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .PhoneScreen {
        border-radius: 20px;
    }

    .PhoneScreen img {
        width: 30vw;
    }

    .PhoneScreen .skeleton {
        width: 30vw;
        height: 70vw;

        border-radius: 20px;
    }
}