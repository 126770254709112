@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;500;600;700;800&display=swap');

html {
  background-color: #FFFFFF;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  opacity: 0.6;
}

.no-hover-effect:hover {
  opacity: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Page404 {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 4em;
  padding-bottom: 4em;
}

.Page404-header {
  font-size: 4em;
}


/* Transition animation */

.fade-enter,
.fade-appear {
  opacity: 0;
  transform: translate(0, 10px);
  z-index: 1;
}

.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 300ms ease-out, transform 300ms ease;
}

.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}

.fade-exit-active {
  opacity: 0;
  transform: translate(0, 10px);

  transition: opacity 300ms ease-out, transform 300ms ease;
}

.StackedCardSection {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-top: -40px;

  position: relative;

  overflow: hidden;
}
