.AULAProject .Banner {
    background-color: #CEEAD9;
}

.AULAProject .shift-down {
    transform: translate(0, 3em);

    animation-name: appear-down;
    animation-duration: 1000ms;
}

.AULAProject .shift-up {
    transform: translate(0, -3em);

    animation-name: appear-up;
    animation-duration: 1000ms;
}

@keyframes appear-up {
    from {
        opacity: 0;
        transform: translate(0, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, -3em);
    }
}

@keyframes appear-down {
    from {
        opacity: 0;
        transform: translate(0, 0);
    }

    to {
        opacity: 1;
        transform: translate(0, 3em);
    }
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .AULAProject .Banner {
        height: 40vh;
        gap: 5vw;
    }

    .AULAProject .shift-down {
        transform: translate(0, 11vh);
    }

    .AULAProject .shift-up {
        transform: translate(0, -11vh);
    }

    @keyframes appear-up {
        from {
            opacity: 0;
            transform: translate(0, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, -11vh);
        }
    }

    @keyframes appear-down {
        from {
            opacity: 0;
            transform: translate(0, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 11vh);
        }
    }
}