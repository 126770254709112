.NavBar {
    font-family: 'Epilogue', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1em;
    padding-bottom: 1em;

    min-height: 3em;
}

.NavBar-links {
    display: flex;
    justify-content: right;
    gap: 3em;
}

.NavBar-link {
    display: flex;
    justify-content: center;
    font-size: 1.1em;
}

.NavBar-logo {
    width: 3em;
}

.NavBar-link:hover {
    opacity: 1;
}

.NavBarActiveLink {
    text-decoration: underline 2px;
    font-weight: 500;
    text-underline-offset: 4px;
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .NavBar {
        padding-left: 2em;
        padding-right: 2em;
        padding-top: 0.6em;
        padding-bottom: 0.6em;
    }

    .NavBar-links {
        gap: 2em;
    }

    .NavBar-link {
        font-size: 1.0em;
    }

    .NavBar-logo {
        width: 2.5em;
    }
}