.Footer-container {
    display: flex;
    justify-content: space-between;

    font-family: 'Epilogue', sans-serif;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.3em;
    background-color: #323232;

    padding-left: 5em;
    padding-right: 5em;
    padding-top: 2em;
    padding-bottom: 2em;

    border-top: 1px solid black;
}

.Contacts-container {
    display: flex;
    flex-direction: column;

    gap: 1em;
}

.Contact-icons {
    display: flex;

    gap: 0.5em;
}

.Footer-links {
    display: flex;
    flex-direction: column;

    gap: 1em;
}

.Footer-logo-container {
    display: flex;

    gap: 1em;
}

.Footer-logo {
    width: 20vw;
    min-width: 10em;
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .Footer-container {
        flex-direction: column;
        gap: 3em;

        padding-left: 2em;
        padding-right: 2em;
    }

    .Contacts-container {
        align-items: center;
    }

    .Footer-logo-container {
        justify-content: center;
    }

    .Footer-links {
        align-items: center;
    }

    .Footer-logo {
        width: 80vw;
        min-width: 10em;
    }
}

.white-link {
    color: #FFFFFF;
}
