.Doc-container {
    font-family: 'Epilogue', sans-serif;
    color: #2D2D2D;
    font-weight: 400;

    display: flex;
    place-content: center;

    padding-top: 4em;
    padding-bottom: 4em;
    padding-left: 2em;
    padding-right: 2em;

    background-color: #FFFFFF;
}

.Doc {
    display: grid;
    grid-template-columns: [col] 40% [col] 60% [col];
    grid-template-rows: [row] auto [row] auto [row];

    width: 75vw;
    max-width: 900px;

    border: 0.5px solid black;
}

.Doc img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.Doc-cell {
    border: 0.5px solid black;
}

.Doc-header-left {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    position: relative;
    overflow: hidden;
}

.Doc-header-left-img {
    position: absolute;
}

.Doc-header-left-extra-img-container {
    position: absolute;

    bottom: 5%;
    left: 5%;

    width: 30%;
}

.Doc-header-right {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-weight: 500;
    font-size: 1.7em;
    line-height: 1.5em;
    letter-spacing: 0.05em;

    padding-left: 1.5em;
    padding-right: 1.5em;

    padding-top: 2em;
    padding-bottom: 2em;
}

.Doc-body {
    grid-column: 1 / 3;
    grid-row: 2 / 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    padding: 2em;

    line-height: 1.6em;
}

.Doc-body .block {
    margin-top: 1em;
    margin-bottom: 1em;
}

.Doc-body .highlights {
    background-color: #D5DEF6;
    color: #0A34A4;
    font-weight: 600;

    padding-top: 1.2em;
    padding-bottom: 1em;
    padding-left: 2.2em;
    padding-right: 2.2em;
}

.Doc-body h1 {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 1.5em;
    letter-spacing: 0.05em;
}

.Doc-photo-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;

    padding-top: 0.7em;
    padding-bottom: 0.7em;

    flex-wrap: wrap;
}

.Photo-Card {
    flex: 0.33;

    height: 250px;
    min-width: 250px;

    display: grid;
    grid-template-columns: [col] 100% [col];
    grid-template-rows: [row] 80% [row] auto [row];

    border: 1px solid black;
    background-color: white;
}

.Photo-Card-title {
    border-top: 1px solid black;

    grid-column: 1 / 2;
    grid-row: 2 / 3;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.9em;
}

.Photo-Card-img-container {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    position: relative;
    overflow: hidden;
}

.Photo-Card-img {
    object-fit: cover;

    width: 100%;
    min-height: 100%;
}

.Doc .link {
    color: #2D2D2D;
    text-decoration: underline;
    font-weight: 600;
}

@media (max-width: 768px) {
    /* For mobile phones: */

    .Doc-container {
        padding-top: 6em;
        padding-bottom: 6em;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .Doc {
        display: grid;
        grid-template-columns: [col] 100% [col];
        grid-template-rows: [row] 200px [row] auto [row] auto [row];

        width: 100%;
    }

    .Doc-header-left {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .Doc-header-right {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .Doc-body {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .Photo-Card {
        min-width: 23em;
        width: 100%;
        height: 23em;


        border: 1px solid black;
    }

    .Photo-Card-title {
        font-size: 1.2em;
    }
}