.lazy-image.original {
    transition: opacity 400ms ease;
}

.lazy-image.original.loading {
    opacity: 0;
    position: fixed;
}

.lazy-image.placeholder {
    filter: blur(20px);

    transition: opacity 400ms ease;
}

.lazy-image.placeholder.loading {
    opacity: 0;
}

.lazy-image.placeholder.hide {
    opacity: 0;
    display: none;
    position: fixed;

    animation-name: placeholder-disappear;
    animation-duration: 400ms;
    animation-delay: 1000ms;
}

@keyframes placeholder-disappear {
    0% {
        display: block;
    }

    99% {
        opacity: 0;
        display: block;
    }

    100% {
        opacity: 0;
        display: none;
    }
}